import './ourServices.css';
import React from 'react';
import ocHart from './ocHart.png'


export function OurServices() {

    return (
        <div className='ourServices  '>

            <div className='col-12  row  ourServicesContainer mx-auto'>

                <div className='GpuInfoContainer   col-xl-5  mx-auto col-11'>
                    Jednym z najważniejszych podzespół komputera jest karta graficzyna. Jej niedostatećzna wydaność powodem niedostatecznej wysajności całego komputera.

                    <div className='Info mt-5'>

                        Istnieją  jednak dwa sposoby na zwiększnie wydajności karty graifcznej

                    </div>   <div className='mt-4'>Pierwszym z nim jest&nbsp;
                        <span className='infoGpu'>
                            overclocking&nbsp;
                        </span>
                        potocznie zwanym podkręcaniem. Zabieg ten polega na podniesieniu zegara taktującego karty graficznej. Szybciej takowana grafika zwiększa szybkośc wykonywanych obliczeń, a tym samym zwiększa wydajność.
                    </div> </div><div className='col-1'></div>

                <div className='col-5 '>
                    <img className='ocHart ' src={ocHart} alt="image of gpu"></img>

                </div>
            </div>
            <div className='mt-5   row  mx-auto col-12'>


                <div className='col-5 mx-auto mt-4 ourServicesContainer GpuInfoContainer '>


                    Kolejnym sposobem na zwiększenie wydajności karty graficznej jest
                    undervolting. Metoda ta polega na zmniejszeniu napięcia rdzenia procesora graficznego co prowadzi do zmniejszenia temperatury karty graficznej. Każdy nowoczesny sprzęt elektroniczny wraz z wzrostem temperatury zmniejsza swoją wydajność, aby nie doprowadzić do przegrzania.
                    Metoda ta jest szczególnie efektywna w przypadach laptopów oraz układów osiągająacyh wysokie tempterury.
                    <div className='Info mt-2'>
                        Mniejsza temperatura = większa wydajność.

                    </div>
                </div>
                <div className='col-1'></div>
                <div className='col-5 '>
                    <img className='ocHart ' src={ocHart} alt="image of gpu"></img>

                </div>
            </div>
            <div className='   row  mx-auto col-12'>


                <div className='col-5 mx-auto mt-4 ourServicesContainer GpuInfoContainer '>


                    Obie metody możemy połączyć, jednocześnie pokręcając kartę graficzną i zmniejszając napięcie rdzenia graficznego.

                    <div className='Info mt-2'>
                        Dzięki niższej temperaturze i podkręceniu karta graficzna pracuje szybciej i wydajniej.

                    </div>
                </div>


                <div className='col-5 mx-auto mt-4 ourServicesContainer GpuInfoContainer '>


Wszyskie nasze usługi wykonujmey   <span className='Info'> zdalnie</span>
                    <div className=' mt-2 '>
Dlatego też możemy pomagać osobą z całej polski!   
                    </div>
                    <div className='Info mt-2'>
Nie ogranicza nas odległości , nie racimy też czasu na dojazd
                    </div>
                </div>
            </div>

        </div>

    )
}